.list {
  margin-top: 5rem;
  margin-bottom: 10rem;

  &__year {
    margin-bottom: 2rem;
    @include fs--heading-1;
  }

  &__post {
    @include fs--heading-3;
  }

  ul {
    margin-bottom: 2.5rem;
    list-style: none;
    padding: 0;
  }

  li {
    padding: 1.5rem 0;
    border-top: 1px solid var(--color-foreground-dark);

    @media screen and (min-width: var($breakpoint-mobile)) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      @include fs--body;
    }

    p {
      @include fs--body;
      color: var(--color-foreground-dark);
    }
  }
}
