/* ==========================================================================
   Mixins
   ========================================================================== */

// Clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin background {
  background-color: var(--color-background);
  background-image: url("/assets/images/backgrounds/paper.png");
  background-repeat: repeat;
  background-blend-mode: darken;

  @media (prefers-color-scheme: dark) {
    background-image: url("/assets/images/backgrounds/paper-inverted.png");
    background-blend-mode: lighten;
  }
}

// Font families
@mixin ff--sans-serif($font-weight: normal) {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: $font-weight;
}

@mixin ff--sans-serif-header($font-weight: normal) {
  font-family: 'League Spartan', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: $font-weight;
}

@mixin ff--code {
  font-family: 'Fira Code', monospace;
}

@mixin ff--andy {
  font-family: 'Fira Code - Andy Hansen', monospace;
}

// Font sizing
@mixin fs--title {
    line-height: 1.5;
    font-size: 3rem; // 48px
}

@mixin fs--heading-1 {
    line-height: 1.5;
    font-size: 2rem; // 32px
}

@mixin fs--heading-2 {
    line-height: 1.5;
    font-size: 1.5rem; // 24px
}

@mixin fs--heading-3 {
    line-height: 1.5;
    font-size: 1.25rem; // 20px
}

@mixin fs--heading-4 {
    line-height: 1.6;
    font-size: 1.1rem; // 18px
}

@mixin fs--body {
    line-height: 1.5;
    font-size: 1.1rem; // 18px
}

@mixin fs--meta {
    line-height: 1;
    font-size: 1.1rem; // 18px
}

@mixin fs--caption {
    line-height: 1;
    font-size: 1rem; // 16px
}

@mixin fs--code {
    font-size: 0.9rem;
    line-height: 1.5;
}

// Visually hide content
@mixin visually-hidden {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

