body {
  font-size: 16px;
  @include ff--sans-serif(400);

  @include background;
}

h1, h2, h3, h4, h5, h6 {
  @include ff--sans-serif-header(800);
  color: var(--color-foreground-dark);
}

h4, h5, h6 {
  @include ff--sans-serif-header(600);
  color: var(--color-foreground-dark);
}

[hidden],
template {
  display: none;
}

img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

svg:not(:root) { overflow: hidden; }

svg {
  pointer-events: none;
  max-height: 100%
}

.center {
  text-align: center;
}

.its-my-name {
  color: var(--color-foreground);
  @include ff--andy;
}

/* Links */

a:not(.plain) {
  color: var(--color-accent);
  box-shadow: var(--color-accent-variant) 0 -2px 0 inset;
  text-decoration: none;
  transition: box-shadow 0.25s ease-out 0s, color 0.25s ease-out 0s;

  &:hover {
    color: var(--color-accent-inverse);
    box-shadow: var(--color-accent) 0 -1.4em inset;
  }
}

a.nav {
  padding: 10px 35px;
  overflow: hidden;
}

img.link-icon {
  display: inline-block;
  height: 1.1em;
  transition: transform .6s ease-in-out;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%);
  }

  &:hover {
    transition: transform 1.2s ease-in-out;
    transform: rotate(180deg);
  }
}

pre {
  overflow: auto;
}

span.small {
  @include fs--caption;
  color: var(--color-foreground-dark);
}

.youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.page-wrapper {
  max-width: 45rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.hidden {
  @include visually-hidden;
}
