.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;

  li {
    float: left; 
  }

  a {
    text-decoration: none;

    &:hover {
      color: var(--color-background)
    }
  }

  .tag {
    background: var(--color-foreground-dark);
    border-radius: 5px;
    color: var(--color-background);
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 50px 0;
    text-decoration: none;
    // transition: color 3s, background-color 3s;

    &::before {
      @include background;

      border-radius: 10px;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
      content: '';
      height: 6px;
      left: 10px;
      position: absolute;
      width: 6px;
      top: 10px;
    }

    &:hover {
      background-color: var(--color-accent-variant);
      color: var(--color-accent);

      &::after {
        border-left-color: var(--color-accent-variant);
      }
    }
  }
}
