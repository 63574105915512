main {
  color: var(--color-foreground);
  margin-bottom: 10rem; // 100px

  & > header {
    margin-bottom: 2rem; // 50px

    @media screen and (min-width: var($breakpoint-mobile)) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .title {
    @include fs--heading-1;
    color: var(--color-foreground-dark);
  }

  .time {
    @include fs--body;
  }

  & > article {
    margin-bottom: 5rem; // 50px
    @include fs--body;

    >* {
      margin-bottom: 1.8rem; // 18px
    }

    h2 {
      @include fs--heading-2;
    }

    h3 {
      @include fs--heading-3;
    }

    h4 {
      @include fs--heading-4;
    }

    h5 {
      @include fs--body;
    }

    strong {
      color: var(--color-foreground-dark);
      font-weight: 700;
    }

    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: 1.2rem;
      border-left: 5px solid var(--color-accent-variant);

      > p {
        margin-bottom: 1em;
      }
    }

    .frame {
      margin-bottom: 100px;

      &.tall {
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;

        @media screen and (max-width: $breakpoint-mobile) {
          max-width: 100%;
        }
      }

      .subtitle {
        font-size: .8em;
        color: var(--color-foreground)
      }
    }

    .framed {
      padding: 20px;
      background-color: var(--color-white);

      @media screen and (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }
  }

  & > footer {
    border-top: 1px solid LightGrey;
    border-bottom: 1px solid LightGrey;
    margin-bottom: 8rem;

    a {
      margin: .5em ;
    }

    div {
      border-color: transparent;
    }

    .page__nav {
      &-wrapper {
        padding: 10px;
      }

      &-left {
        text-align: left;
      }

      &-right {
        text-align: right;
      }
    }
  }
}
