.page {
  & > header {
    margin-bottom: 1rem;

    h1 {
      margin-bottom: 1.2rem;
      @include fs--heading-2;
      color: var(--color-foreground-dark);
    }

    p {
      @include fs--meta;
    }
  }

  & > article {
    padding: 3rem 0 0 0;
    margin-bottom: 2rem;
  }

  & > footer {
    margin-bottom: 10rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    p {
      @include fs--body;
      color: var(--color-foreground);
    }
  }
}
