@font-face {
	font-family: "League Spartan";
	src: 
		url("/assets/fonts/spartan/LeagueSpartan-VF.woff2") format("woff2"),
		url("/assets/fonts/spartan/LeagueSpartan-VF.woff") format("woff"),
		url("/assets/fonts/spartan/LeagueSpartan-VF.ttf") format("truetype");
	font-weight: 100 1000;
	font-stretch: 25% 150%;
}

@font-face {
	font-family: "Fira Code";
	src: url("/assets/fonts/fira-code/FiraCode-VariableFont.ttf") format("truetype");
	font-weight: 100 1000;
}

// Only includes enough characters to spell Andy Hansen
@font-face {
	font-family: "Fira Code - Andy Hansen";
	src: url("/assets/fonts/fira-code/FiraCode-Regular-AndyHansen.ttf");
	font-weight: 400;
}